import { Button } from '@mui/material';
import React, { useState, useEffect } from 'react';

import QRCode from 'qrcode';

import ReceiptPrinterEncoder from '@point-of-sale/receipt-printer-encoder';

function PrintQR() {
    const [device, setDevice] = useState(null);

    async function printWithRawBT(sections) {
        let encoder = new ReceiptPrinterEncoder();
        // Configura el encoder para tu impresora
        encoder
        .initialize()
      
        // Añade cada sección y su código QR al recibo
        for (const section of sections) {
            encoder.text(section.seccion)
            encoder.newline()
            encoder.qrcode(section.qrCode)
            encoder.newline()
            encoder.newline()
        }
      
        // Genera el contenido en formato binario
        const encodedContent = encoder.encode();
      
        // Convierte el contenido a base64
        const base64Content = btoa(String.fromCharCode(...new Uint8Array(encodedContent)));
      
        // Crea la URL de impresión para rawBT
        const rawBTUrl = `rawbt:base64,${base64Content}`;
      
        // Llama a rawBT para enviar el contenido
        window.location.href = rawBTUrl;
    }

    async function printMultipleQR(seccionesQR) {
        let rawBTData = '';
      
        // Genera el QR en base64 para cada sección
        for (const item of seccionesQR) {
          const { seccion, qrCode } = item;
      
          // Genera el código QR en formato base64
          const qrBase64 = await QRCode.toDataURL(qrCode);
      
          // Añade el texto y el código QR en formato de imagen para rawBT
          rawBTData += `Sección: ${seccion}\n[IMG:${qrBase64}]\n\n`;
        }
      
        // Convierte el contenido a Base64 para el esquema rawBT
        const base64Data = btoa(rawBTData);
        const url = `rawbt:base64,${base64Data}`;
      
        // Envía la URL a rawBT en Android
        window.location.href = url;
    }

    function printMultipleQR2(seccionesQR) {
        // Inicializa el contenido de impresión
        let rawBTData = "";
      
        // Itera sobre las secciones y códigos QR para construir el contenido
        /*seccionesQR.forEach((item) => {
          const { seccion, qrCode } = item;
          rawBTData += `TEXT ${seccion}\n`;
          // Añade la sección y el código QR en el formato que `rawBT` espera
          rawBTData += `QR ${qrCode}\n\n`;
        });*/

        rawBTData += `QR ${'HOLA MUNDO!'}\n\n`;
        const base64Data = btoa(rawBTData);
        const url = `rawbt:base64,${base64Data}`;
      
        // Envía la URL a rawBT en Android
        window.location.href = url;
      
        // Codifica y lanza la intención
        //const encodedData = encodeURIComponent(rawBTData);
        //window.location.href = `intent://print/#Intent;scheme=rawbt;package=ru.a402d.rawbtprinter;type=text/plain;S.text=${encodedData};end;`;
    }
    
    // Ejemplo de uso
    const datosAImprimir = [
        { seccion: "General-18-1", qrCode: "https://example.com/a" },
        /*{ seccion: "Sección B", qrCode: "https://example.com/b" },
        { seccion: "Sección C", qrCode: "https://example.com/c" }*/
    ];

    function BtPrint(prn){
        var S = "#Intent;scheme=rawbt;";
        var P =  "package=ru.a402d.rawbtprinter;end;";
        var textEncoded = encodeURI(prn);
        window.location.href="intent:"+textEncoded+S+P;
    }

  return (
    <div style={{marginTop:200,marginLeft:200}}>
        <Button onClick={()=>printWithRawBT(datosAImprimir)}>Imprimir</Button>
    </div>
  );
}

export default PrintQR;