import { useContext, useEffect, useReducer } from "react";
import { Box, Container, Grid, Paper, TextField, Typography, InputAdornment, Checkbox, Link, Button, Avatar } from "@mui/material";
import SectionTitle from "../../components/sectionTitle";

import login_photo from '../../assets/images/website/loginui.png';
import logo from '../../assets/images/logos/black.png';

import cwg from '../../assets/images/stores/cwg.png';

import EmailIcon from '@mui/icons-material/Email';
import PasswordIcon from '@mui/icons-material/Password';
import AlternateEmailIcon from '@mui/icons-material/AlternateEmail';
import ModalDialog from "../../components/modalDialog";
import { useHistory } from "react-router-dom";
import { LoadingButton } from "@mui/lab";
import { personSignUp } from "../../controllers/AuthController";
import AuthContext from "../../navigation/context/authContext";
import GoogleLogin from "react-google-login";

import { gapi } from 'gapi-script';
import { loadGapiInsideDOM } from 'gapi-script';
import SocialAuth from "./SocialAuth";


const emailReg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;


//DECLARAMOS LOS ESTADOS INICIALES
const initialState = {
      
    email : '', errorEmail : false,
    password : '', errorPassword : false,
    
    isLoading : false,
    errorSignIn : false,
    errorSignInMsg : '',

    isOpen : false,
    title:'',
    msg:''
  }
  
  function reducer( state, { field, value })
  {
    return{
        ...state,
        [field] : value
    }
  }
  

export default function SignIn()
{
    const [state,setState] = useReducer(reducer,initialState);
    const {
        email, errorEmail,
        password, errorPassword,

        isLoading,

        isOpen,
        title,
        msg,

    } = state;

    const { signIn } = useContext(AuthContext);

    useEffect(()=>{
        function start() {
            gapi.client.init({
              clientId: '617727461618-03sc7bftdp5bmh6cnjeftp23nuf5ad6i.apps.googleusercontent.com',
              scope: 'email',
            });
          }
      
          gapi.load('client:auth2', start);
    },[]);

    const googleSuccess = async (res) => {
        console.log(res.profileObj)
        setState({field:'isLoading',value:true});
        const email = res.profileObj.email;
        const password = res.profileObj.googleId;
        const oAuth = true;
        await signIn({email,password,oAuth});
            //redirect('/')
        
        setState({field:'isLoading',value:false});
    };

    const googleFailure = (error) => {
        console.log(error);
        console.log("Google Sing In ha fracasado intentelo denuevo mas tarde");
    };

    const onChange = (e) => {
        setState({field : e.target.name, value : e.target.value});
    }

    const history = useHistory();
    const redirect = (route) => {
        history.push(route)
    }


    const formVerification = () => {
        var ban = 0;

        if(email === '' /*|| emailReg.test(email) === false*/)
        {
            setState({field:'errorEmail',value:true});
            ban = 1;
        }
            
        if(password === '')
        {
            setState({field:'errorPassword',value:true});
            ban = 1;
        }
        
        return ban;
    }

    /**
     * this function checks if all input fields of the form are filled
     * then if the form fields are full, execute the submit
     */
    const handleSubmit = async() => {
        await setState({field:'isLoading',value:true});
        if(formVerification() === 0)
        {
            await signIn({email,password});
            await setState({field:'isLoading',value:false});
            //redirect('/')
        }
        await setState({field:'isLoading',value:false});
    }



    const handleclose = async () => {
        if(title === 'Operacion Exitosa!')
        {
            setState({field:'title',value:''});
            setState({field:'msg',value:''});
            setState({field:'isOpen',value:false});         
        }
        else
        {
            setState({field:'title',value:''});
            setState({field:'msg',value:''});
            setState({field:'isOpen',value:false});
        }
    }


    return(
        <Container maxWidth='xl' sx={{mt:15}}>
            <Grid container spacing={3}>
                <Grid item lg={5} xl={5} sx={{display:{xs:'none',sm:'none',md:'none',lg:'block',xl:'block'}}} >
                    <Box sx={{width:'100%'}}>
                        <img
                            style={{width:'100%',borderRadius:20}}
                            src={login_photo}
                            alt=""
                        />
                    </Box>
                </Grid>
                <Grid item lg={7} xl={7} display={'flex'} alignItems={'center'}>
                    <Grid container spacing={3} justifyContent={'center'}>
                        <Grid item lg={7} xl={7} display={'flex'} justifyContent={'center'}>
                            <Box sx={{width:85,height:85,display:'flex',justifyContent:'center',alignItems:'center'}}>
                                <img
                                    style={{width:85,height:85}}
                                    src={logo}
                                    alt=""
                                />
                            </Box>
                        </Grid>
                        <Grid item lg={6} xl={6}>
                            <Box
                                component='form'
                                sx={{
                                    width:'100%',
                                    '& .MuiTextField-root':{mb:3}
                                }}
                                spacing={2}
                                noValidate
                                autoComplete="off"
                            >
                                <Typography variant="h6" textAlign={'center'} mb={3}>Inicia sesión en tu cuenta</Typography>
                                {/**Email */}
                                <TextField
                                    fullWidth
                                    id="email"
                                    label="Correo"
                                    placeholder='Correo'
                                    name='email'
                                    variant="outlined"
                                    InputProps={{
                                        startAdornment: (
                                        <InputAdornment position="start">
                                            <EmailIcon />
                                        </InputAdornment>
                                        ),
                                    }}
                                    value={email}
                                    onChange = { onChange }
                                    onBlur={ () => (email === '' ? setState({field:'errorEmail',value:true}) : setState({field:'errorEmail',value:false}) ) }
                                    error = {errorEmail}
                                    helperText={errorEmail && 'Ingrese el correo electronico'}
                                />

                                <TextField
                                    fullWidth
                                    id="password"
                                    name='password'
                                    label="Contraseña"
                                    placeholder='Contraseña'
                                    variant="outlined"
                                    InputProps={{
                                        startAdornment: (
                                        <InputAdornment position="start">
                                            <PasswordIcon />
                                        </InputAdornment>
                                        ),
                                    }}
                                    type="password"
                                    value={password}
                                    onChange = { onChange }
                                    onBlur={ () => (password === '' ? setState({field:'errorPassword',value:true}) : setState({field:'errorPassword',value:false}) ) }
                                    error = {errorPassword}
                                    helperText={errorPassword && 'Ingrese la contraseña'}
                                />                    
                                
                                <Box sx={{display:'flex',flexDirection:'row',justifyContent:'center'}}>
                                    <Button sx={{textTransform:'none',mb:2}} onClick={()=>redirect('/auth/forgotPassword')}>
                                        <Typography variant="body2" textAlign={'center'} fontWeight={'bold'}>
                                            ¿Olvidaste tu contraseña?
                                        </Typography>
                                    </Button>
                                </Box>
                                <LoadingButton
                                    fullWidth
                                    variant='contained'
                                    loading={isLoading}
                                    onClick={()=>handleSubmit()}
                                >
                                    Iniciar Sesión
                                </LoadingButton>

                                {/**SOCIALS AUTH */}
                                <Box sx={{display:'flex',flexDirection:'column',alignItems:'center',mt:2,mb:2}}>
                                    {/**separator */}
                                    <Box sx={{display:'flex',flexDirection:'row',justifyContent:'center',width:'100%'}}>
                                        <Grid container spacing={1}>
                                            <Grid item xs={3} sm={4} md={4} lg={5} xl={5} sx={styles.separatorContainer}>
                                                <Box sx={styles.separator}/>
                                            </Grid>
                                            <Grid item xs={6} sm={4} md={4} lg={2} xl={2}>
                                                <Typography textAlign={'center'}>or</Typography>
                                            </Grid>
                                            <Grid item xs={3} sm={4} md={4} lg={5} xl={5} sx={styles.separatorContainer}>
                                                <Box sx={styles.separator}/>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                </Box>

                                <SocialAuth/>

                                {/*<Box sx={{mt:5,display:'flex',width:'100%',justifyContent:'center',alignItems:'center'}}>
                                    <GoogleLogin
                                        clientId="617727461618-03sc7bftdp5bmh6cnjeftp23nuf5ad6i.apps.googleusercontent.com"
                                        buttonText="Continuar con Google"
                                        onSuccess={googleSuccess}
                                        onFailure={googleFailure}
                                        //cookiePolicy={'single_host_origin'}
                                    />
                                </Box>*/}
                                
                                <Box sx={{display:'flex',flexDirection:'row',justifyContent:'center',mt:5}}>
                                    <Button sx={{textTransform:'none'}} onClick={()=>redirect('/auth/userType')}>
                                        <Typography variant="body2">
                                            ¿Eres nuevo? <span style={{fontWeight:'bold'}}>Registrate</span>
                                        </Typography>
                                    </Button>
                                </Box>  
                            </Box>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Container>
    )
}


const styles = {
    separator:
    {
        border:'solid',
        borderWidth:1,
        justifyContent:'center',
        width:'100%',
        borderColor:'#CBCEC9'
    },
    separatorContainer:
    {
        display:'flex',
        flexDirection:'row',
        justifyContent:'center',
        alignItems:'center'
    }
}



/**
 * <Box
            sx={{
                display:'flex',
                flexDirection:'column',
                alignItems:'center',
                justifyContent:'center',
                pb:5
            }}
        >
        <Container maxWidth='sm'>
            <SectionTitle title='Inicia sesion en tu cuenta'/>
            
            <Paper elevation={6} sx={{pt:5,pb:5}}>
                
                <Box
                    component='form'
                    sx={{
                        width:'100%',
                        '& .MuiTextField-root':{mb:3}
                    }}
                    spacing={2}
                    noValidate
                    autoComplete="off"
                >
                    
                    <TextField
                        fullWidth
                        id="email"
                        label="Correo"
                        placeholder='Correo'
                        name='email'
                        variant="outlined"
                        InputProps={{
                            startAdornment: (
                            <InputAdornment position="start">
                                <EmailIcon />
                            </InputAdornment>
                            ),
                        }}
                        value={email}
                        onChange = { onChange }
                        onBlur={ () => (email === '' ? setState({field:'errorEmail',value:true}) : setState({field:'errorEmail',value:false}) ) }
                        error = {errorEmail}
                        helperText={errorEmail && 'Ingrese el correo electronico'}
                    />

                    <TextField
                        fullWidth
                        id="password"
                        name='password'
                        label="Contraseña"
                        placeholder='Contraseña'
                        variant="outlined"
                        InputProps={{
                            startAdornment: (
                            <InputAdornment position="start">
                                <PasswordIcon />
                            </InputAdornment>
                            ),
                        }}
                        type="password"
                        value={password}
                        onChange = { onChange }
                        onBlur={ () => (password === '' ? setState({field:'errorPassword',value:true}) : setState({field:'errorPassword',value:false}) ) }
                        error = {errorPassword}
                        helperText={errorPassword && 'Ingrese la contraseña'}
                    />

                    <div style={{display:'flex',flexDirection:'row',alignItems:'center',marginTop:10}}>
                        <Checkbox
                                //checked={agree} 
                               /* onChange={()=>{ 
                                    setState({field:'agree',value:!agree})} 
                                }*
                                name="agree"
                                id='agree' 
                                //onBlur={ () => (agree === false ? setState({field:'errorAgree',value:true}) : setState({field:'errorAgree',value:false}) ) }
                            />
                        <Typography>Permanecer conectado</Typography>
                    </div>

                    
                    <br/>
                    <LoadingButton
                        fullWidth
                        variant='contained'
                        loading={isLoading}
                        onClick={()=>handleSubmit()}
                    >
                        Iniciar Sesión
                    </LoadingButton>

                
                    <Box sx={{display:'flex',flexDirection:'column',alignItems:'center',mt:2,mb:2}}>
                      
                        <Box sx={{display:'flex',flexDirection:'row',justifyContent:'center',width:'100%'}}>
                            <Grid container spacing={1}>
                                <Grid item xs={3} sm={4} md={4} lg={4} xl={4} sx={styles.separatorContainer}>
                                    <Box sx={styles.separator}/>
                                </Grid>
                                <Grid item xs={6} sm={4} md={4} lg={4} xl={4}>
                                    <Typography textAlign={'center'}>or continue with</Typography>
                                </Grid>
                                <Grid item xs={3} sm={4} md={4} lg={4} xl={4} sx={styles.separatorContainer}>
                                    <Box sx={styles.separator}/>
                                </Grid>
                            </Grid>
                        </Box>
                    </Box>

                    <SocialAuth/>
                    <Box style={{marginTop:50}}>
                        <Grid container>
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                <Button sx={{textTransform:'none'}} onClick={()=>redirect('/auth/forgotPassword')}>
                                    <Typography style={{fontSize:13}}>
                                        ¡Olvide Contraseña!
                                    </Typography>
                                </Button>
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                <Button sx={{textTransform:'none'}} onClick={()=>redirect('/auth/userType')}>
                                    <Typography style={{fontSize:13}}>
                                        ¿Eres nuevo? Registrate
                                    </Typography>
                                </Button>
                            </Grid>
                        </Grid>
                    </Box>  
                </Box>
            </Paper>

            <ModalDialog
                open={isOpen}
                title={title}
                msg={msg}
                handleClose={()=>handleclose()}
                handleAction={()=>handleclose()}
            />
        </Container>
        </Box>
 */